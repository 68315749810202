/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Typography, DrawerBody } from '@one-thd/sui-atomic-components';
import moment from 'moment';
import { func, object, string as str } from 'prop-types';
import { Location } from '@one-thd/sui-icons';
import { useDataModel } from '@thd-nucleus/data-sources';
import WrappedFields from './WrappedFields';
import { MAX_PARTICIPANTS, OTHER_OPT } from '../utils/constants';

function FormContent({
  setRegistrationData,
  workshopsData,
  setDrawerPage,
  svocId,
  storeId,
  toggleDrawer,
}) {
  const { data } = useDataModel('storeSearch', {
    variables: {
      storeSearchInput: storeId,
      pagesize: 1,
    },
  });

  const [disableButton, setDisableButton] = useState(false);
  const store = data?.storeSearch?.stores[0];

  const handleRegister = async (info) => {
    setDrawerPage('loading');
    const url = '/workshops/rs/participant';
    const splitName = info.name.trim().split(' ');
    const firstName = splitName[0];
    const lastName = splitName[1] || '';
    const workshop = info.workshop.target.value;
    const formattedStartDate = moment(workshop.startDate).utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';
    const formattedEndDate = moment(workshop.endDate).utc().format('YYYY-MM-DD[T]HH:mm:ss') + 'Z';

    let howDidYouHear = info.howDidYouHear.target.value;
    if (howDidYouHear === OTHER_OPT) howDidYouHear = info.other;

    const participants = info.participants || 1;
    const cappedParticipants = participants <= MAX_PARTICIPANTS ? participants : MAX_PARTICIPANTS;

    const body = [
      {
        firstName,
        lastName,
        email: info.email.toLowerCase(),
        svocId: svocId || '123',
        numberOfParticipants: cappedParticipants,
        howDidYouHear,
        workshop: {
          id: workshop.id,
          name: workshop.name,
          storeId: workshop.storeId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          description: workshop.description,
          type: workshop.type,
          imagePath: workshop.imagePath,
        },
        store: {
          storeId: store.storeId,
          name: store.name,
          phone: store.phone,
          address: store.address,
        },
      },
    ];

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const apiData = response.status;
    if (apiData === 200) {
      setDrawerPage('success');
      setRegistrationData({
        name: firstName + ' ' + lastName,
        numOfParticipants: cappedParticipants,
        storeId: store.storeId,
        storeName: store.name,
        workshop: workshop.name,
        date: workshop.startDate,
        address: store.address,
      });
    }
  };

  return (
    <div className="sui-grid sui-gap-4 sui-text-left">
      <DrawerBody className="sui-px-2 sui-py-2">
        <div className="sui-grid sui-grid-cols-1">
          <Typography variant="h5" weight="bold">
            <div className="sui-flex sui-items-center sui-gap-2 sui-mb-1">
              <Location />
              <span>
                {store?.name} #{store?.storeId}
              </span>
            </div>
          </Typography>
        </div>
        <div className="sui-grid sui-grid-cols-1 sui-mt-2">
          <Typography variant="body-base" weight="regular">
            <span className="sui-text-subtle">
              Need a different location? Update your store and try registering again.
            </span>
          </Typography>
        </div>
        <WrappedFields
          onSubmit={handleRegister}
          workshopsData={workshopsData}
          disableButton={disableButton}
          setDisableButton={setDisableButton}
          storeId={store?.storeId}
          onCloseDrawer={toggleDrawer(false)}
        />
      </DrawerBody>
    </div>
  );
}

FormContent.propTypes = {
  setRegistrationData: func.isRequired,
  workshopsData: object.isRequired,
  setDrawerPage: func.isRequired,
  svocId: str,
  storeId: str.isRequired,
  toggleDrawer: func.isRequired,
};

FormContent.defaultProps = {
  svocId: '123'
};

export default FormContent;
